import { createMuiTheme, Theme } from '@material-ui/core/styles'

const theme: Theme = createMuiTheme({
  palette: {
    primary: {
      dark: '#146f5a',
      main: '#1ea788',
      light: '#81e8d1',
    },
    neutral: {
      dark: '#1a1a1a',
      main: '#353535',
      light: '#e6e6e6',
    },
  },
  typography: {
    fontFamily: ['Lato', 'sans-serif'].join(','),
  },
})

export { theme }
