import React, { useState } from 'react'
import styled from 'styled-components'

import LoginForm from './components/LoginForm'
import codiblyLogo from './images/codibly_logo.png'
import Header from './components/Header'
import MountTransition from './components/MountTransition'
import SuccessScreen from './components/SuccessScreen'

const Wrapper = styled.div`
  flex-grow: 1;
  display: grid;
  grid-template-columns: 3fr 4fr;
  background: url("https://cdn.shortpixel.ai/client/q_glossy,ret_img/https://codibly.com/wp-content/uploads/2020/07/careers-banner.jpg") no-repeat;
  background-position: center;
  background-size: cover;
  overflow: auto;

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.values.sm}px) {
    display: flex;
    justify-content: center;
    padding: 0px 8px;
  }
`

const LeftSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 32px 96px;
  background-color: #fafafa;
  min-height: auto;

  @media screen and (max-width: ${({ theme }) =>
  theme.breakpoints.values.md}px) {
    padding: 32px;
  }

  @media screen and (max-width: ${({ theme }) =>
  theme.breakpoints.values.sm}px) {
    margin: auto 0;
    padding: 16px 24px;
    height: initial;
  }
`

const LoginSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 32px 96px;
  background-color: #fafafa;
  min-height: auto;
  height: 100%;

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.values.md}px) {
    padding: 32px;
  }

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.values.sm}px) {
    margin: auto 0;
    padding: 16px 24px;
    height: initial;
  }
`

const CodiblyLogo = styled.div`
  background: url(${codiblyLogo}) no-repeat 50%;
  background-size: 50%;
  height: 100px;
`

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  return (
    <Wrapper>
      <LeftSectionWrapper>
      <LoginSection data-testid='app__login-section'>

        {!isLoggedIn ? (
          <MountTransition>
            <Header
              title='Hey Buddy!'
              subtitle="Welcome to Codibly’s QA Automation Recruitment Task"
            />
            <LoginForm onLoginSuccess={() => setIsLoggedIn(true)} />
          </MountTransition>
        ) : (
          <SuccessScreen />
        )}



      </LoginSection>
        <CodiblyLogo />
      </LeftSectionWrapper>
    </Wrapper>
  )
}

export default App
